<template>
  <div class="list-container">
    <div v-for="(item, index) in datasource" :key="`li-${name}-${index}`" class="d-flex list-item">
      <input
        :id="`_${name}-${index}`"
        type="checkbox"
        class="form-check-input"
        :checked="selectedItems.includes(item)"
        @click="toggleSelection(item)"
        />
      <label :for="`_${name}-${index}`" class="form-check-label ps-2">{{ item }}</label>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  name: string
  datasource: string[]
  preselectedItems: string[]
  exclusiveItems?: string[]
}>()

const selectedItems = ref<string[]>([])
const peopleSelected = ref<boolean>(false)


const emit = defineEmits(['OnItemSelected', 'OnPeopleSelected'])

if (props.preselectedItems) {
  selectedItems.value.push(...props.preselectedItems)
}

function toggleSelection(item: string) {
  if (props.exclusiveItems?.includes(item)) {
    if (item === 'People') {
      peopleSelected.value = !peopleSelected.value
      emit('OnPeopleSelected', peopleSelected.value)
    } else {
      selectedItems.value = [item]
      emit('OnItemSelected', selectedItems.value)
    }
  } else {
    selectedItems.value = selectedItems.value.filter((x) => !props.exclusiveItems?.includes(x))
    if (selectedItems.value.includes(item)) {
      selectedItems.value = selectedItems.value.filter((x) => x !== item)
    } else {
      selectedItems.value.push(item)
    }
    emit('OnItemSelected', selectedItems.value)
  }
}


function updateSelectedItems(items: string[]) {
  selectedItems.value = items
}

defineExpose({
  updateSelectedItems
})
</script>

<style lang="scss" scoped>
.list-container {
  overflow-y: scroll;
  max-height: 370px;
  border: 1px solid #ccc;
  padding: 4px 0 0 0;
}

.list-item {
  padding: 10px 20px 10px 12px;
}
</style>
